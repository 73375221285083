import { NextPageContext } from 'next';
import { createTRPCNext } from '@trpc/next';
import { httpBatchLink, httpLink, loggerLink, splitLink } from '@trpc/client';
import { QueryCache, MutationCache } from '@tanstack/react-query';
import superjson from 'superjson';
import { toast } from 'react-toastify';

import type {
	// v10 types
	inferRouterInputs,
	inferRouterOutputs,
	// v9 types
	inferProcedureInput,
	inferProcedureOutput,
} from '@trpc/server';
import type { AppRouter } from '~/server/routers/_app';

import ErrorToastBody from '~/components/error-handling/ErrorToastBody';

export const transformer = superjson;
/**
 * This is a helper method to infer the output of a query resolver
 * @example type HelloOutput = inferQueryOutput<'hello'>
 */
export type inferQueryOutput<TRouteKey extends keyof AppRouter['_def']['queries']> = inferProcedureOutput<AppRouter['_def']['queries'][TRouteKey]>;

export type inferQueryInput<TRouteKey extends keyof AppRouter['_def']['queries']> = inferProcedureInput<AppRouter['_def']['queries'][TRouteKey]>;

export type inferMutationOutput<TRouteKey extends keyof AppRouter['_def']['mutations']> = inferProcedureOutput<
	AppRouter['_def']['mutations'][TRouteKey]
>;

export type inferMutationInput<TRouteKey extends keyof AppRouter['_def']['mutations']> = inferProcedureInput<
	AppRouter['_def']['mutations'][TRouteKey]
>;

/** tRPC 10 inference helpers **/
export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;

export interface SSRContext extends NextPageContext {
	/**
	 * Set HTTP Status code
	 * @usage
	 * const utils = trpc.useContext();
	 * if (utils.ssrContext) {
	 *   utils.ssrContext.status = 404;
	 * }
	 */
	status?: number;
}

function getBaseUrl() {
	if (typeof window !== 'undefined') {
		return '';
	}
	// reference for vercel.com
	if (process.env.VERCEL_URL) {
		return `https://${process.env.VERCEL_URL}`;
	}

	// assume localhost
	return `http://localhost:${process.env.PORT ?? 3000}`;
}

/**
 * Extend `NextPageContext` with meta data that can be picked up by `responseMeta()` when server-side rendering
 */

const queryCache = new QueryCache({
	onError: (error) => {
		toast.error(ErrorToastBody({ error }), {
			toastId: undefined,
			position: 'top-right',
			autoClose: false,
			hideProgressBar: false,
			closeOnClick: false,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	},
});

const mutationCache = new MutationCache({
	onError: (error) => {
		// @ts-ignore If error.data doesn't exist, no harm
		if (error?.data?.code === 'CONFLICT') {
			console.warn('Not showing an error toast for a CONFLICT error', error);
			return;
		}
		toast.error(ErrorToastBody({ error }), {
			toastId: undefined,
			position: 'top-right',
			autoClose: false,
			hideProgressBar: false,
			closeOnClick: false,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	},
});

export const trpc = createTRPCNext<AppRouter, SSRContext>({
	overrides: {
		useMutation: {
			/**
			 * This function is called whenever a `.useMutation` succeeds
			 * From https://trpc.io/docs/v10/client/react/useUtils#invalidate-full-cache-on-every-mutation
			 **/
			async onSuccess(opts) {
				/**
				 * @note that order here matters:
				 * The order here allows route changes in `onSuccess` without
				 * having a flash of content change whilst redirecting.
				 **/
				// Calls the `onSuccess` defined in the `useQuery()`-options:
				await opts.originalFn();

				if (opts.meta?.skipQueryInvalidation) {
					return;
				}

				// Invalidate all queries in the react-query cache:
				await opts.queryClient.invalidateQueries();
			},
		},
	},
	config() {
		/**
		 * If you want to use SSR, you need to use the server's full URL
		 * @link https://trpc.io/docs/ssr
		 */
		return {
			/**
			 * @link https://trpc.io/docs/links
			 */
			links: [
				// adds pretty logs to your console in development and logs errors in production
				loggerLink({
					enabled: (opts) => process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error),
				}),
				/**
				 * This allows us to skip batched request on a by query basis. Docs below.
				 * @link https://trpc.io/docs/client/links/splitLink#disable-batching-for-certain-requests
				 * adding a {trpc: context: {skipBatch: true}} to the query context will skip batching for that query.
				 */
				splitLink({
					condition(op) {
						// check for context property `skipBatch`
						return op.context.skipBatch === true;
					},
					// when condition is true, use normal request
					true: httpLink({
						url: `${getBaseUrl()}/api/trpc`,
					}),
					// when condition is false, use batching
					false: httpBatchLink({
						url: `${getBaseUrl()}/api/trpc`,
						/**
						 * Set custom request headers on every request from tRPC
						 * @link https://trpc.io/docs/ssr
						 */
						// headers() {
						// 	if (!ctx?.req?.headers) {
						// 		return {};
						// 	}
						// 	// To use SSR properly, you need to forward the client's headers to the server
						// 	// This is so you can pass through things like cookies when we're server-side rendering

						// 	const {
						// 		// If you're using Node 18 before 18.15.0, omit the "connection" header
						// 		connection: _connection,
						// 		...headers
						// 	} = ctx.req.headers;
						// 	return headers;
						// },
					}),
				}),
			],
			/**
			 * @link https://trpc.io/docs/data-transformers
			 */
			transformer,
			/**
			 * @link https://tanstack.com/query/v4/docs/react/reference/QueryClient
			 *
			 * For documentation of query options:
			 * @link https://tanstack.com/query/v4/docs/react/reference/useQuery
			 */
			queryClientConfig: {
				queryCache: queryCache,
				mutationCache: mutationCache,
				defaultOptions: {
					queries: {
						// Error is of type unknown through QueryClient
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						retry: (failureCount, error: any) => {
							if (error.httpStatus) {
								if (error.httpStatus === '404') {
									// if resource not found, don't keep querying
									return false;
								} else {
									return failureCount < 3;
								}
							} else {
								// retry 3 times
								return failureCount < 3;
							}
						},
						//Don't refresh when the window gets focus back
						refetchOnWindowFocus: false,
						//Don't refresh when a bad connection is restored
						refetchOnReconnect: false,
					},
				},
			},
		};
	},
	/**
	 * @link https://trpc.io/docs/ssr
	 */
	ssr: true,
	/**
	 * Set headers or status code when doing SSR
	 */
	responseMeta(opts) {
		const ctx = opts.ctx as SSRContext;

		if (ctx.status) {
			// If HTTP status set, propagate that
			return {
				status: ctx.status,
			};
		}

		const error = opts.clientErrors[0];
		if (error) {
			// Propagate http first error from API calls
			return {
				status: error.data?.httpStatus ?? 500,
			};
		}

		// for app caching with SSR see https://trpc.io/docs/caching

		return {};
	},
});
