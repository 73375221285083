import { EndSystem, Role } from '@prisma/client';
import { createContext, ReactNode, useContext } from 'react';
import { trpc } from '~/utils/trpc';
import { useAuth } from '@clerk/nextjs';
import { JobSummary } from '@services/context.service';
import { FeatureFlag } from '@utils/featureFlags';
import { ErrorScreen } from '~/components/error-handling/ErrorScreen';
import { LoadingScreen } from '~/components/LoadingScreen';
import { PermissionKey } from '@schema/permission.schema';
export interface IUserState {
	autoSyncQuickbooks: boolean;
	businessCategorySummary: JobSummary;
	businessId: number | null;
	businessName: string | null;
	businessTimezone: string;
	defaultGlassCostCodeId: number | null;
	defaultLaborCostCodeId: number | null;
	defaultMaterialCostCodeId: number | null;
	emailSignature: string | null;
	email: string | null;
	featureFlags: FeatureFlag[];
	firstName: string | null;
	id: number | null;
	isQuoteLineItemToggleEnabled: boolean;
	lastName: string | null;
	phone: string | null;
	quickbooksEnabled: boolean | null;
	quickbooksEndSystem: EndSystem | null;
	quickEstimatesCount: number;
	role: Role | null;
	allowedPermissions: PermissionKey[];
	shouldBccOnSentEmails: boolean;
	shouldSendEmailNotifications: boolean;
	shouldSendSmsNotifications: boolean;
	isShownOnSchedule: boolean;
	taskCount: number;
	unreadNotificationsCount?: number;
	taskCountWithEmailDeliveryFailed?: number;
	keyDatesCount?: number;
	tilledEnabled: boolean;
	hiddenEstimateColumns: string[] | null;
}

// TODO: Want to add more user info here but not working for some reason, for now using useUser()
export const UserContext = createContext<IUserState | null>(null);

export const useUserContext = () => {
	const userContext = useContext(UserContext);

	if (!userContext) {
		throw new Error('User Context Data Error');
	}

	return userContext;
};

type Props = {
	children: ReactNode;
};

export const UserProvider = (props: Props) => {
	const { isLoaded, userId } = useAuth();

	if (isLoaded && userId) {
		const userContextQuery = trpc.context.forUser.useQuery({ clerkUserId: userId }, { refetchOnWindowFocus: false });

		if (userContextQuery.isSuccess) {
			const { data } = userContextQuery;

			if (data.role) {
				const userContextValues = {
					autoSyncQuickbooks: data.autoSyncQuickbooks,
					businessCategorySummary: data.businessCategorySummaries,
					businessId: data.businessId,
					businessName: data.businessName,
					businessTimezone: data.businessTimezone,
					defaultGlassCostCodeId: data.defaultGlassCostCodeId,
					defaultLaborCostCodeId: data.defaultLaborCostCodeId,
					defaultMaterialCostCodeId: data.defaultMaterialCostCodeId,
					email: data.email,
					emailSignature: data.emailSignature,
					featureFlags: data.featureFlags,
					firstName: data.firstName,
					id: data.id,
					isQuoteLineItemToggleEnabled: data.isQuoteLineItemToggleEnabled,
					lastName: data.lastName,
					phone: data.phone,
					quickbooksEnabled: data.hasQuickbooks,
					quickbooksEndSystem: data.quickbooksEndSystem,
					quickEstimatesCount: data.quickEstimatesCount,
					role: data.role,
					shouldBccOnSentEmails: data.shouldBccOnSentEmails,
					shouldSendEmailNotifications: data.shouldSendEmailNotifications,
					shouldSendSmsNotifications: data.shouldSendSmsNotifications,
					isShownOnSchedule: data.isShownOnSchedule,
					taskCount: data.taskCount,
					unreadNotificationsCount: data.unreadNotificationsCount,
					keyDatesCount: data.keyDatesCount,
					taskCountWithEmailDeliveryFailed: data.taskCountWithEmailDeliveryFailed,
					tilledEnabled: data.tilledEnabled,
					hiddenEstimateColumns: data.hiddenEstimateColumns,
					allowedPermissions: data.allowedPermissions,
				} as IUserState;

				return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>;
			} else {
				return <ErrorScreen message={'No role found for user.'} />;
			}
		} else if (userContextQuery.isLoading) {
			return <LoadingScreen srText="Loading" />;
		} else if (userContextQuery.isError) {
			return (
				<ErrorScreen
					status={userContextQuery.error?.data?.code === 'NOT_IMPLEMENTED' ? 'METHOD_NOT_SUPPORTED' : userContextQuery.error?.data?.code}
				/>
			);
		} else {
			return <ErrorScreen message={'Error loading user.'} />;
		}
	} else if (userId === null) {
		return <ErrorScreen message={'No auth ID found.'} />;
	} else {
		return <LoadingScreen srText="Loading" />;
	}
};
