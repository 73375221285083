import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import { MainNavProps } from '~/components/navigation/NavPropTypes';
import { FAIcon } from '../utils/FAIcons';
import clsx from 'clsx';
import { useNavStore } from '~/stores/nav.store';
import { isNotNullish } from '@utils/types';

export const VerticalNav = ({ navItems, pathname, collapsed, collapsible = true, closable = false }: MainNavProps) => {
	const [hideNavItem, setHideNavItem] = useState({});

	const { collapsedNav, setCollapsedNav, setSidebarOpen } = useNavStore();

	useEffect(() => {
		navItems.navigation.forEach((item) => {
			if (item.subItems?.length)
				setHideNavItem((prev) => ({
					...prev,
					[item.name]: false,
				}));
		});
	}, []);

	return (
		<nav className={clsx('mt-5 flex flex-col justify-between', { 'items-center': collapsed })} aria-label="Sidebar Menu">
			<div className="px-2 space-y-1 flex flex-col">
				{navItems.navigation.map((item) => {
					const active = item.href === pathname || (isNotNullish(pathname) && item.otherPaths?.includes(pathname));
					return (
						<Fragment key={item.name}>
							<div
								className={clsx('flex items-center group-hover:text-gray-900 rounded-md group', {
									'bg-gray-100 text-secondary group-hover:bg-gray-200 group-hover:text-gray-900': active,
									'text-white hover:bg-gray-200 hover:text-gray-900': !active,
									'justify-center': collapsed,
									'justify-between': !collapsed,
								})}
							>
								<Link
									href={item.href}
									className={clsx('cursor-pointer items-center p-3 md:text-lg font-medium rounded-md justify-center', {
										'text-secondary group-hover:text-gray-900': active,
										'text-white group-hover:text-gray-900': !active,
										'flex': collapsed,
										'grid grid-cols-8': !collapsed,
									})}
									onClick={closable ? () => setSidebarOpen(false) : undefined}
								>
									<>
										<FAIcon
											prefix="fad"
											icon={item.icon ? item.icon : ''}
											className={clsx('text-base place-self-center', {
												'col-span-6': collapsed,
												'col-span-1 mr-2': !collapsed,
											})}
										/>
										<span
											className={clsx('text-xs  ease-linear whitespace-nowrap', {
												'col-span-4': item.notification || item.subItems?.length,
												'col-span-5': !(item.notification || item.subItems?.length),
												'hidden': collapsed,
											})}
										>
											{item.name}
										</span>
									</>
								</Link>

								{!collapsed && item.rightIcon && (
									<FAIcon
										prefix="fad"
										icon={item.rightIcon}
										className="text-base place-self-center col-span-1 mr-2 text-yellow-300"
									/>
								)}

								{!!item.notification && !collapsed && (
									<div className="place-self-center col-span-1 text-sm">
										<div
											className={clsx(
												active
													? 'text-gray-100 bg-menu-dark'
													: 'bg-gray-100 text-menu-dark group-hover:bg-menu-dark group-hover:text-gray-100',
												'ml-2 mr-2 h-5 min-w-[1.25rem] px-1 inline-flex items-center justify-center rounded-md'
											)}
										>
											{item.notification}
										</div>
									</div>
								)}
								{!hideNavItem[item.name] && item.subItems && item.subItems?.length > 0 && !collapsed ? (
									<div
										className="cursor-pointer"
										onClick={() => setHideNavItem((previous) => ({ ...previous, [item.name]: true }))}
									>
										<FAIcon icon="chevron-down" className="text-sm place-self-end mr-2" />
									</div>
								) : (
									item.subItems &&
									item.subItems.length > 0 &&
									!collapsed && (
										<div
											className="cursor-pointer"
											onClick={() => setHideNavItem((previous) => ({ ...previous, [item.name]: false }))}
										>
											<FAIcon icon="chevron-up" className="text-sm place-self-end mr-2" />
										</div>
									)
								)}
							</div>
							{hideNavItem[item.name] &&
								item.subItems &&
								item.subItems.map((subItem) => {
									const subItemActive =
										subItem.href === pathname?.split('?')[0] ||
										(isNotNullish(pathname?.split('?')[0]) && subItem.otherPaths?.includes(pathname.split('?')[0]!));
									if (Number(subItem.notification) <= 0) return null;

									return (
										<Link
											key={subItem.name}
											href={subItem.href}
											passHref
											onClick={closable ? () => setSidebarOpen(false) : undefined}
											className={clsx(
												subItemActive
													? 'bg-gray-100 text-secondary hover:bg-gray-200 hover:text-gray-900'
													: 'text-white hover:bg-gray-200 hover:text-gray-900',
												'group grid grid-cols-6 items-center p-3 md:text-lg font-medium rounded-md'
											)}
										>
											<FAIcon
												prefix="fad"
												icon={subItem.icon ? subItem.icon : ''}
												className={clsx('text-base place-self-center', {
													'col-span-6': collapsed,
													'col-span-1 mr-2': !collapsed,
												})}
											/>
											<span
												className={clsx({
													'col-span-4': subItem.notification,
													'col-span-5': !subItem.notification,
													'text-xs': true,
													'hidden': collapsed,
												})}
											>
												{subItem.name}
											</span>
											{subItem.notification && !collapsed && (
												<div className="place-self-center col-span-1 text-sm">
													<div
														className={clsx(
															subItemActive
																? 'text-gray-100 bg-menu-dark'
																: 'bg-gray-100 text-menu-dark group-hover:bg-menu-dark group-hover:text-gray-100',
															'h-5 min-w-[1.25rem] px-1 inline-flex items-center justify-center rounded-md'
														)}
													>
														{subItem.notification}
													</div>
												</div>
											)}
										</Link>
									);
								})}
						</Fragment>
					);
				})}
			</div>
			<div className="px-2 space-y-1 mb-2 flex flex-col justify-center">
				{navItems.bottomNav?.map((item) => {
					const active = item.href === pathname;

					return (
						<div
							key={item.name}
							className={clsx('flex items-center group-hover:text-gray-900 rounded-md group', {
								'bg-gray-100 text-secondary group-hover:bg-gray-200 group-hover:text-gray-900': active,
								'text-white hover:bg-gray-200 hover:text-gray-900': !active,
								'justify-center': collapsed,
								'justify-between': !collapsed,
							})}
						>
							<Link
								href={item.href}
								className={clsx(
									'cursor-pointer items-center p-3 md:text-lg font-medium rounded-md justify-center transition-all duration-300 ease-in-out',
									{
										'text-secondary group-hover:text-gray-900': active,
										'text-white group-hover:text-gray-900': !active,
										'flex': collapsed,
										'grid grid-cols-8': !collapsed,
									}
								)}
								onClick={closable ? () => setSidebarOpen(false) : undefined}
							>
								<FAIcon
									prefix="fad"
									icon={item.icon ? item.icon : ''}
									className={clsx('text-base place-self-center', { 'col-span-6': collapsed, 'col-span-1 mr-2': !collapsed })}
								/>
								<span className={clsx('col-span-5 text-xs', { hidden: collapsed })}>{item.name}</span>
							</Link>
						</div>
					);
				})}
				<div
					onClick={() => {
						collapsible ? setCollapsedNav(!collapsedNav) : setSidebarOpen(false);
					}}
					className="flex items-center cursor-pointer md:text-lg font-medium rounded-md text-gray-100 hover:bg-gray-200 hover:text-gray-900"
				>
					<FAIcon
						prefix="fad"
						icon={`${collapsed ? 'arrow-right-to-line' : 'arrow-left-to-line'}`}
						className="text-base px-2 text-glazier-blue-400"
					/>
					<span className={clsx('text-xs font-medium text-glazier-blue-400', { hidden: collapsed })}>Collapse Menu</span>
				</div>
			</div>
		</nav>
	);
};
