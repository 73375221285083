import { JobPhase, Role } from '@prisma/client';
import { CustomJobPhase } from '@services/context.service';
import { FeatureFlag, isFeatureEnabled } from '@utils/featureFlags';
import { titlifyEnum } from '@utils/stringHelpers';
import { ReactNode } from 'react';
import { useFeatureFlagContext } from '~/react_context/FeatureFlagContext';
import { useUserContext } from '~/react_context/UserContext';
import { JobSubView } from '~/server/schema/job.schema';
import { PermissionKey } from '@schema/permission.schema';

// Function to check if the user has a specific permission
function hasPermission(userPermissions: PermissionKey[], permission: PermissionKey): boolean {
	return userPermissions.includes(permission);
}

// Function to check if the user's role is allowed for a particular item
function isRoleAllowed(role: Role, allowedRoles: Role[]): boolean {
	return allowedRoles.includes(role); // Ensure role is not null
}

export const useSideNavItemsForRole = (role: Role | null, userPermissions: PermissionKey[]) => {
	const { taskCount, unreadNotificationsCount, taskCountWithEmailDeliveryFailed, businessCategorySummary, quickEstimatesCount } = useUserContext();

	// If there's no role on this user something went sideways somewhere.
	if (!role) {
		return { navigation: [] };
	}

	function getBusinessCategoryIcon(customJobPhase: CustomJobPhase, subView: JobSubView) {
		return Object.entries(businessCategorySummary[customJobPhase]).map(([key, value]) => ({
			name: titlifyEnum(key),
			icon: value.icon,
			href: `/jobs/category/${key.toLowerCase()}?subView=${subView}`,
			notification: value.jobCount,
		}));
	}

	function sumNotificationByCategory(key: CustomJobPhase) {
		return Object.entries(businessCategorySummary[key]).reduce((acc, [_, value]) => acc + value.jobCount, 0);
	}

	const navItems = {
		navigation: [
			{
				name: 'Dashboard',
				icon: 'gauge-circle-bolt',
				href: '/',
				permissionKey: PermissionKey.MENU_DASHBOARD,
				allowedRoles: [Role.FULL, Role.TECHNICIAN],
			},
			businessCategorySummary[JobPhase.ESTIMATE]
				? {
						name: 'My Bids',
						icon: 'bullseye-arrow',
						href: `/jobs?subView=${JobSubView.ALL_OPPORTUNITIES}`,
						notification: sumNotificationByCategory(JobPhase.ESTIMATE),
						subItems: getBusinessCategoryIcon(JobPhase.ESTIMATE, JobSubView.ALL_OPPORTUNITIES),
						permissionKey: PermissionKey.MENU_JOBS,
						allowedRoles: [Role.FULL],
					}
				: {
						name: 'Jobs',
						icon: 'bullseye-arrow',
						href: '/jobs',
						permissionKey: PermissionKey.MENU_JOBS,
						allowedRoles: [Role.FULL, Role.TECHNICIAN],
					},
			businessCategorySummary[JobPhase.WORK_ORDER]
				? {
						name: 'Won Jobs',
						icon: 'briefcase',
						href: `/jobs?subView=${JobSubView.ALL_WON_JOBS}`,
						notification: sumNotificationByCategory(JobPhase.WORK_ORDER),
						subItems: getBusinessCategoryIcon(JobPhase.WORK_ORDER, JobSubView.ALL_WON_JOBS),
						permissionKey: PermissionKey.MENU_JOBS,
						allowedRoles: [Role.FULL],
					}
				: {
						name: 'Won Jobs',
						icon: 'briefcase',
						href: `/jobs?subView=${JobSubView.ALL_WON_JOBS}`,
						permissionKey: PermissionKey.MENU_JOBS,
						allowedRoles: [Role.FULL],
					},
			businessCategorySummary[JobPhase.JOB_LOST]
				? {
						name: 'Closed',
						icon: 'lock',
						href: `/jobs?subView=${JobSubView.CLOSED}`,
						notification: sumNotificationByCategory(JobPhase.JOB_LOST),
						subItems: getBusinessCategoryIcon(JobPhase.JOB_LOST, JobSubView.CLOSED),
						permissionKey: PermissionKey.MENU_JOBS,
						allowedRoles: [Role.FULL],
					}
				: {
						name: 'Closed',
						icon: 'lock',
						href: `/jobs?subView=${JobSubView.CLOSED}`,
						permissionKey: PermissionKey.MENU_JOBS,
						allowedRoles: [Role.FULL],
					},
			businessCategorySummary['FLAGGED']
				? {
						name: 'Flagged',
						icon: 'flag-checkered',
						href: `/jobs?subView=${JobSubView.FLAGGED}`,
						notification: sumNotificationByCategory('FLAGGED'),
						subItems: getBusinessCategoryIcon('FLAGGED', JobSubView.FLAGGED),
						permissionKey: PermissionKey.MENU_JOBS,
						allowedRoles: [Role.FULL],
					}
				: {
						name: 'Flagged',
						icon: 'flag-checkered',
						href: `/jobs?subView=${JobSubView.FLAGGED}`,
						permissionKey: PermissionKey.MENU_JOBS,
						allowedRoles: [Role.FULL],
					},
			{
				name: 'Quick Estimates',
				icon: 'file-pen',
				href: '/quick-estimate',
				notification: quickEstimatesCount,
				permissionKey: PermissionKey.MENU_QUICK_ESTIMATES,
				allowedRoles: [Role.FULL, Role.TECHNICIAN],
			},
			{
				name: 'Inbox',
				icon: 'bell',
				href: '/inbox',
				notification: !!unreadNotificationsCount ? unreadNotificationsCount : undefined,
				rightIcon: taskCountWithEmailDeliveryFailed ? 'exclamation-triangle' : '',
				permissionKey: PermissionKey.MENU_INBOX,
				allowedRoles: [Role.FULL],
			},
			{ name: 'Customers', icon: 'address-book', href: '/customers', permissionKey: PermissionKey.MENU_CUSTOMERS, allowedRoles: [Role.FULL] },
			{
				name: 'Time Tracking',
				icon: 'clock-three',
				href: '/time-tracking',
				permissionKey: PermissionKey.MENU_TIME_TRACKING,
				allowedRoles: [Role.FULL],
			},
			{
				name: 'Scheduling',
				icon: 'calendar-day',
				href: '/schedule?view=Daily',
				permissionKey: PermissionKey.MENU_SCHEDULING,
				allowedRoles: [Role.FULL, Role.TECHNICIAN],
			},
			{
				name: 'Purchase Orders',
				icon: 'cart-shopping',
				href: '/purchase-orders',
				permissionKey: PermissionKey.MENU_PURCHASE_ORDERS,
				allowedRoles: [Role.FULL],
			},
			{
				name: 'Suppliers & Materials',
				icon: 'screwdriver-wrench',
				href: '/materials',
				permissionKey: PermissionKey.MENU_SUPPLIERS_AND_MATERIALS,
				allowedRoles: [Role.FULL],
			},
			{
				name: 'Accounting',
				href: '/billing',
				icon: 'money-bill-transfer',
				permissionKey: PermissionKey.MENU_TRANSACTIONS,
				allowedRoles: [Role.FULL],
				subItems: [
					{
						name: 'Billing',
						href: '/billing',
						icon: 'file-invoice-dollar',
						permissionKey: PermissionKey.MENU_TRANSACTIONS,
						allowedRoles: [Role.FULL],
					},
					{
						name: 'Transactions',
						href: '/transactions',
						icon: 'money-check-dollar',
						permissionKey: PermissionKey.MENU_TRANSACTIONS,
						allowedRoles: [Role.FULL],
					},
				],
			},
			{
				name: 'Reporting',
				icon: 'chart-line-up',
				href: '/reporting/jobs',
				subItems: [
					{
						name: 'Jobs',
						icon: 'chart-user',
						href: '/reporting/jobs',
						permissionKey: PermissionKey.MENU_REPORTING_JOBS,
						allowedRoles: [Role.FULL],
					},
					{
						name: 'Estimates',
						icon: 'chart-user',
						href: '/reporting/estimates',
						permissionKey: PermissionKey.MENU_REPORTING_ESTIMATES,
						allowedRoles: [Role.FULL],
					},
					{
						name: 'Inventory',
						icon: 'shelves',
						href: '/reporting/inventory',
						otherPaths: ['/reporting/estimateInventory'],
						permissionKey: PermissionKey.MENU_REPORTING_ESTIMATES,
						allowedRoles: [Role.FULL],
					},
					{
						name: 'Purchase Orders',
						icon: 'chart-user',
						href: '/reporting/purchaseOrders',
						permissionKey: PermissionKey.MENU_REPORTING_PURCHASE_ORDERS,
						allowedRoles: [Role.FULL],
					},
					{
						name: 'Customers',
						icon: 'chart-user',
						href: '/reporting/customers',
						permissionKey: PermissionKey.MENU_REPORTING_JOBS,
						allowedRoles: [Role.FULL],
					},
					{
						name: 'AR Transactions',
						icon: 'chart-user',
						href: '/reporting/transactions',
						permissionKey: PermissionKey.MENU_REPORTING_AR_TRANSACTIONS,
						allowedRoles: [Role.FULL],
					},
					{
						name: 'Sync Log',
						icon: 'right-left-large',
						href: '/reporting/sync-log',
						permissionKey: PermissionKey.MENU_REPORTING_JOBS,
						allowedRoles: [Role.FULL],
					},
				].filter((item) => hasPermission(userPermissions, item.permissionKey) && isRoleAllowed(role, item.allowedRoles)), // Filter subItems
				permissionKey: PermissionKey.MENU_REPORTING_JOBS,
				allowedRoles: [Role.FULL],
			},
		],
		bottomNav: [{ name: 'Settings', icon: 'gears', href: '/settings', permissionKey: PermissionKey.MENU_SETTINGS, allowedRoles: [Role.FULL] }],
	};

	// Filter navigation and bottomNav based on both user permissions and role
	return {
		navigation: navItems.navigation.filter(
			(item) => hasPermission(userPermissions, item.permissionKey) && isRoleAllowed(role, item.allowedRoles)
		),
		bottomNav: navItems.bottomNav.filter((item) => hasPermission(userPermissions, item.permissionKey) && isRoleAllowed(role, item.allowedRoles)),
	};
};
export const useJobNavItemsForRole = (
	role: Role | null,
	jobRoot: string,
	isCommercial: boolean,
	numberOfEstimates: number,
	userPermissions: PermissionKey[] = [] // Add userPermissions as input
) => {
	const enabledFeatureFlags = useFeatureFlagContext();

	// If there's no role on this user something went sideways somewhere.
	if (!role) {
		return [];
	}

	const navItems = [
		{
			id: 'job-nav-overview',
			name: 'Overview',
			href: jobRoot,
			icon: 'gauge-circle-bolt',
			permissionKey: PermissionKey.JOB_MENU_OVERVIEW,
			allowedRoles: [Role.FULL, Role.TECHNICIAN],
		},
		{
			id: 'job-nav-attachments',
			name: 'Attachments',
			href: `${jobRoot}/attachments?view=Grid`,
			icon: 'file-image',
			permissionKey: PermissionKey.JOB_MENU_ATTACHMENTS,
			allowedRoles: [Role.FULL, Role.TECHNICIAN],
		},
		{
			id: 'job-nav-estimates',
			name: 'Estimates',
			href: numberOfEstimates === 1 ? `${jobRoot}/estimates/latest` : `${jobRoot}/estimates`,
			compareHref: `${jobRoot}/estimates`,
			icon: 'file-signature',
			permissionKey: PermissionKey.JOB_MENU_ESTIMATES,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-change-orders',
			name: 'Change Orders',
			href: `${jobRoot}/change-orders`,
			icon: 'exchange',
			permissionKey: PermissionKey.JOB_MENU_CHANGE_ORDERS,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-glass-builder',
			name: 'Glass Builder',
			href: `${jobRoot}/glass-builder`,
			icon: 'tools',
			permissionKey: PermissionKey.JOB_MENU_GLASS_BUILDER,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-work-orders',
			name: 'Work Orders',
			href: `${jobRoot}/work-orders`,
			icon: 'gauge-circle-bolt',
			permissionKey: PermissionKey.JOB_MENU_WORK_ORDERS,
			allowedRoles: [Role.FULL, Role.TECHNICIAN],
		},
		{
			id: 'job-nav-floor-plans',
			name: 'Floor Plan',
			href: `${jobRoot}/floor-plan`,
			icon: 'house-building',
			permissionKey: PermissionKey.JOB_MENU_FLOORPLANS,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-measures-and-installs',
			name: 'Measures & Installs',
			href: `${jobRoot}/site-photos`,
			icon: 'ruler-combined',
			permissionKey: PermissionKey.JOB_MENU_MEASURES_AND_INSTALLS,
			allowedRoles: [Role.FULL, Role.TECHNICIAN],
		},
		{
			id: 'job-nav-daily-reports',
			name: 'Daily Reports',
			href: `${jobRoot}/daily-reports`,
			icon: 'file-chart-column',
			permissionKey: PermissionKey.JOB_MENU_DAILY_REPORTS,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-quote-requests',
			name: 'Quote Requests',
			href: `${jobRoot}/quote-requests`,
			icon: 'comments',
			permissionKey: PermissionKey.JOB_MENU_QUOTE_REQUESTS,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-purchase-orders',
			name: 'Purchase Orders',
			href: `${jobRoot}/purchase-orders`,
			icon: 'shopping-cart',
			permissionKey: PermissionKey.JOB_MENU_PURCHASE_ORDERS,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-phases',
			name: 'Phases',
			href: `${jobRoot}/phases`,
			icon: 'tasks',
			permissionKey: PermissionKey.JOB_MENU_PHASES,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-key-dates',
			name: 'Key Dates',
			href: `${jobRoot}/key-dates/edit`,
			icon: 'gauge-circle-bolt',
			permissionKey: PermissionKey.JOB_MENU_KEY_DATES,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-payments',
			name: 'Payments',
			href: `${jobRoot}/payments`,
			icon: 'sack-dollar',
			permissionKey: PermissionKey.JOB_MENU_PAYMENTS,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-job-costing',
			name: 'Job Costing',
			href: `${jobRoot}/job-costing`,
			icon: 'chart-mixed',
			permissionKey: PermissionKey.JOB_MENU_JOB_COSTING,
			allowedRoles: [Role.FULL],
		},
		{
			id: 'job-nav-job-sync-log',
			name: 'Accounting Sync',
			href: `${jobRoot}/sync-log`,
			icon: 'right-left-large',
			permissionKey: PermissionKey.JOB_MENU_ESTIMATES,
			allowedRoles: [Role.FULL],
		},
	];

	if (isFeatureEnabled(enabledFeatureFlags, FeatureFlag.SMART_TAKEOFF)) {
		navItems.push({
			id: 'job-nav-smart-takeoff',
			name: 'Smart Takeoff',
			href: `${jobRoot}/smart-takeoff`,
			icon: 'brain-circuit',
			permissionKey: PermissionKey.JOB_MENU_FLOORPLANS,
			allowedRoles: [Role.FULL],
		});
	}

	// Add the commercial billing item if it's a commercial job
	if (isCommercial) {
		navItems.push({
			id: 'job-nav-commercial-billing',
			name: 'Billing',
			href: `${jobRoot}/commercial-billing`,
			icon: 'briefcase',
			permissionKey: PermissionKey.JOB_MENU_COMMERCIAL_BILLING,
			allowedRoles: [Role.FULL],
		});
	}

	// Filter navItems based on both permissions and role
	return navItems.filter((item) => hasPermission(userPermissions, item.permissionKey) && isRoleAllowed(role, item.allowedRoles));
};
